$color-primary: #1e2844;

$color-secondary: #04a7ea;
$color-secondary-dark: #04a7ea;

$color-grey-dark: #393939;
$color-grey: #9c9c9c;
$color-grey-lines: #ededed;
$color-grey-light: #f8f8f8;
$color-black: #262b31;

$color-red: #e12037;
$color-green: #5faf59;
