@import 'src/styles/config';

html {
  font-size: 10px;
  min-height: calc(var(--vh, 1vh) * 100 - 0.01px);
  height: 100%;
  overflow: visible;
}

body,
#root {
  height: 100%;
}
#root {
  display: flex;
  flex-direction: column;
}

body {
  color: $color-grey-dark;
  font-size: 1.4rem;
  font-family: Arial, Tahoma, Geneva, sans-serif;
}
.container {
  width: 100%;
  padding-right: 2.4rem;
  padding-left: 2.4rem;
  margin-right: auto;
  margin-left: auto;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 3rem #fff inset !important;
  -webkit-text-fill-color: #000 !important;
}

#root {
  min-height: 100%;
  height: auto;
}

@media (min-width: map-get($breakpoints, 'lg') + px) {
  body {
    &::-webkit-scrollbar {
      width: 0.6rem;
      height: 0.6rem;
      background-color: rgba(gray, 0.2);
      border-radius: 0.6rem;
      cursor: pointer;
    }
    &::-webkit-scrollbar-track {
      border-radius: 0.4rem;
      background-color: rgba($color-secondary, 0.1);
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 0.7rem;
      background-color: rgba($color-secondary, 1);
    }
  }
}

@media (max-width: map-get($breakpoints, 'xl') - 1 + px) {
  .container {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }
}
