@import 'src/styles/config';

.wrap {
  min-height: 100%;
  display: grid;
  grid-template-areas: 'header content' 'footer footer';
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr auto;
  flex-grow: 1;
  width: 100%;
  margin: 0 auto;
}

.headerWrapper {
  grid-area: header;
  padding: 4rem 3rem;
  display: grid;
  align-items: center;
  justify-content: center;
  background: $color-secondary;
}

.content {
  position: relative;
  grid-area: content;
  padding: 0 3rem;
  display: grid;
  align-items: center;
}

.footer {
  position: absolute;
  bottom: 1.6rem;
  left: 3rem;
  color: white;
  grid-area: footer;
}

.imageLtr {
  transform: rotate(180deg);
}

.form {
  max-width: 37rem;
  width: 100%;
  min-height: 30rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
}

.logo {
  width: 200px;
  margin-bottom: 4rem;
}

@media (max-width: map-get($breakpoints, 'md') - 1 + px) {
  .wrap {
    grid-template-areas: 'header' 'content' 'footer';
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr auto;
    padding: 0;
    position: relative;
    overflow: hidden;
    &::before {
      display: block;
      content: '';
      position: absolute;
      bottom: -2.5rem;
      right: -6.5rem;
      background: url('../../images/logo.svg') no-repeat 50% 50%;
      background-size: cover;
      z-index: -1;
      opacity: 0.25;
      width: 20rem;
      height: 17.8rem;
    }
  }
  .content {
    padding: 0 1.6rem 5rem;
  }
  .footer {
    padding: 1.6rem;
  }
}
