@import 'src/styles/config';
.root {
}
.item {
  padding: 0 2rem 0 0;
  &Icon {
    min-width: $panel-width;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &Text {
    padding-left: 2rem;
    margin: 0;
    &Inner {
      padding-top: 0.6rem;
      padding-bottom: 0.6rem;
    }
  }

  // states
  &Inner {
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
  }
  &:not(.itemInner) {
    min-height: 4.9rem;
  }
}
.icon {
  transition: transform ease-in 0.3s;
  transform-origin: center;
  color: $color-grey;
  &Open {
    transform: scaleY(-1);
  }
}
